import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trash2, Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getProdList, getSalesOrder, updateMaterialRequest } from "../../../api";
import { convertDateYYMMDD } from "../../../common/Functions/CommonFunctions";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object()
  .shape({
    req_date: yup
      .date()
      .required("Please select date!")
      .typeError("Please select date!"),
    req_status: yup
      .array()
      .min(1, "Please select status!")
      .required("Please select status!"),
    remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
    items: yup.array().of(
      yup.object().shape({
        product: yup
          .array()
          .min(1, "Please select product!")
          .required("Please select product!"),
        quantity: yup
          .string()
          .required("Quantity is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid quantity!"),
      })
    ),
  })
  .required();

function EditMaterialRequest(props) {
  // console.log("props", props);
  const [visible, setVisible] = useState(false);
  const [salesOrders, setSalesOrders] = useState({ data: [], loading: false });
  const [prodList, setProdList] = useState({ data: [], loading: false });

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            sales_order: [],
            product: [],
            quantity: 0,
          },
        ],
      },
    }); // initialise the hook

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const [statusList, setStatusList] = useState({
    data: [
      { id: 1, name: "Draft" },
      { id: 2, name: "Finalized" },
      { id: 3, name: "Closed" },
    ],
    loading: false,
  });

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < props.data.material_req_lineitems.length; i++) {
      temp.push({
        uuid: props.data.material_req_lineitems?.[i]?.uuid,
        // sales_order: [
        //   {
        //     productType:
        //       location.state.so_order_lines[i].product_id.product_type_id
        //         .productType,
        //     uuid: location.state.so_order_lines[i].product_id.product_type_id
        //       .uuid,
        //   },
        // ],
        // product: [
        //   {
        //     uuid: location.state.so_order_lines[i].product_id.uuid,
        //     productId: location.state.so_order_lines[i].product_id.productId,
        //     productName:
        //       location.state.so_order_lines[i].product_id.productName,
        //     diameter: location.state.so_order_lines[i].product_id.diameter,
        //     partNo: location.state.so_order_lines[i].product_id.partNo,
        //   },
        // ],
        quantity: props.data.material_req_lineitems?.[i]?.qty,
      });
    }
    setValue("items", temp, {shouldValidate : true});
    setValue("req_date", new Date(props.data.material_req_date), {shouldValidate : true});
    setValue("req_status", props.data.status !== "" && props.data.status !== null ? [{name: props.data.status}] : [], {shouldValidate : true});
    setValue("remark", props.data.remarks, {shouldValidate : true});
  }, [props.data])
  

  function fetchSalesOrder() {
    setSalesOrders({ ...salesOrders, data: [], loading: true });

    getSalesOrder({ so_status: "Approved" }).then(
      (res) => {
        // console.log("res", res.data.data)
        setSalesOrders({ ...salesOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setSalesOrders({ ...salesOrders, data: [], loading: false });
      }
    );
  }

  function fetchProdList() {
    setProdList({ ...prodList, data: [], loading: true });

    getProdList().then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );
        setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
        let reqPayload = [];
        for (let i = 0; i < data.items.length; i++) {
            const lineItems = {
                uuid:"",
                product_uuid: data.items?.[i]?.product?.[0]?.uuid,
                sales_order_uuid: data.items?.[i]?.sales_order?.[0]?.uuid ?? null,
                sales_order_item_uuid: data.items?.[i]?.sales_order?.[0]?.uuid ?? null,
                quantity: data.items?.[i]?.quantity
            };
            reqPayload.push(lineItems);
        }

    const payload = {
        uuid: "",
        material_req_date: convertDateYYMMDD(data.req_date, "-"),
        material_req_remarks: data.remark,
        material_req_status: data.req_status?.[0]?.name,
        material_req_line_items: reqPayload
    }

      updateMaterialRequest(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Material request updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchMaterialRequestList(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_material_request
            ? props.constLabel.lbl_edit_material_request
            : "Edit Material Request"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"lg"}
        backdrop={"static"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_material_request
              ? props.constLabel.lbl_update_material_request
              : "Update Material Request"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_date ? props.constLabel.lbl_date : "Date"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    control={control}
                    name="req_date"
                    // defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.req_date
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  {errors.req_date && (
                    <span className="text-danger err-msg">
                      {errors.req_date.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mt-md-0 mt-3 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_status ? props.constLabel.lbl_status : "Status"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="req_status"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        // size="sm"
                        options={statusList.data}
                        isLoading={statusList.loading}
                        placeholder="Select status"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.req_status ? true : false}
                      />
                    )}
                  />
                  {!statusList.loading && !errors.req_status && (
                    <span className="arrow-icon"></span>
                  )}
                  <span className="text-danger err-msg">
                    {errors.req_status?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_remark ? props.constLabel.lbl_remark : "Remark"}
                  </label>

                  <textarea
                    type="text"
                    className="form-control"
                    id="remark"
                    name="remark"
                    defaultValue={props.data.remarks}
                    placeholder="Enter remark"
                    {...register("remark")}
                  ></textarea>
                </div>
              </div>
            </div>
            <hr className="mb-1" />
            <div className="d-flex justify-content-between align-items-center">
              <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                {props.constLabel?.lbl_item_detail
                  ? props.constLabel?.lbl_item_detail
                  : "Item Detail"}
              </p>
              <div>
                <a
                  style={{ fontSize: "16px" }}
                  className="btn btn-sm button-primary px-2 mt-2"
                  onClick={() => {
                    append({
                      sales_order: [],
                      product: [],
                      quantity: 0,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      color: "#ffffff",
                      marginRight: "5px",
                    }}
                    icon={faAdd}
                    size="sm"
                  />{" "}
                  {props.constLabel?.lbl_add ? props.constLabel?.lbl_add : "Add"}
                </a>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="col-12">
              {/* {fields.length > 0 ? ( */}
              <div className="">
                <table className="table my-2 table-stirped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {props.constLabel?.lbl_sales_order
                          ? props.constLabel?.lbl_sales_order
                          : "Sales Order"}
                      </th>
                      <th>
                        {props.constLabel?.lbl_product
                          ? props.constLabel?.lbl_product
                          : "Product"}
                        <span className="text-danger"> *</span>
                      </th>
                      <th>
                        {props.constLabel?.lbl_quantity
                          ? props.constLabel?.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th>{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id}>
                        <td style={{ verticalAlign: "top" }} width={"30%"}>
                          <Controller
                            name={`items[${index}].sales_order`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="so_id"
                                multiple={false}
                                options={salesOrders.data}
                                isLoading={salesOrders.loading}
                                placeholder="Select sales order"
                                onChange={(selected) => {
                                  onChange(selected);
                                  if (selected && selected.length > 0) {
                                    // handleSalesOrder(index, selected);
                                  } else {
                                    setValue(`items[${index}].product`, []);
                                  }
                                }}
                                onBlur={onBlur}
                                selected={value}
                                // isInvalid={
                                //   errors.items?.[index]?.sales_order
                                //     ? true
                                //     : false
                                // }
                              />
                            )}
                          />
                        </td>

                        <td style={{ verticalAlign: "top" }} width={"40%"}>
                          <Controller
                            name={`items[${index}].product`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="productName"
                                multiple={false}
                                options={prodList.data}
                                isLoading={prodList.loading}
                                placeholder="Select product"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.product ? true : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.product && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.product.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.quantity
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].quantity`}
                            {...register(`items[${index}].quantity`)}
                            placeholder="Enter quantity"
                          />

                          {errors.items?.[index]?.quantity && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.quantity.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          <Tooltip id={"delete-tooltip" + index} place="top" />
                          <Trash2
                            data-tooltip-id={"delete-tooltip" + index}
                            data-tooltip-content={"Delete"}
                            className="menu_icon_red cursor_pointer"
                            size={20}
                            onClick={
                              index > 0
                                ? () => {
                                    remove(index);
                                  }
                                : null
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditMaterialRequest;
