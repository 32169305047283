import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  updateProduct,
  gethsn_master,
} from "../../../../api";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import info from '../../../../assets/images/info.png';
import AddProductFamily from "../../AddMasters/AddProductFamily";
import AddProductCategory from "../../AddMasters/AddProductCategory";
import AddMarketGroup from "../../AddMasters/AddMarketGroup";

const schema = yup
  .object()
  .shape({
    // marketGroup: yup
    //   .array()
    //   .required("Please select market group")
    //   .min(1, "Please select market group"),
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    openingStock: yup
      .string()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^[0-9]+$/, "Enter valid opening stock")
      .nullable(),
    OEMnumber: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    productName: yup.string().required("Product name is required !"),
    unit: yup
      .array()
      .required("Unit is required !")
      .min(1, "Please select unit !"),
    machineCost: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost of manufacturing"),
    salesPrice: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Procurement price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid procurement price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
  })
  .required();

function GeneralItem(props) {
  let navigate = useNavigate();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [hsnList, setHSNList] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ hsn_sac_code: "" });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));

    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    // fetchHSN();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchHSN();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchHSN() {
    setHSNList({ ...hsnList, data: [], loading: true });
    let payload = {
      pageNo: 1,
      entriesPerPage: 1000,
    };
    if (
      filterConfig.hsn_sac_code !== "" &&
      filterConfig.hsn_sac_code.length >= 4
    ) {
      payload.hsn_sac_code = filterConfig.hsn_sac_code.trim(); // Search by hsn sac code
      gethsn_master(payload).then(
        (res) => {
          setHSNList({ ...hsnList, data: res.data.data, loading: false });
        },
        (err) => {
          setHSNList({ ...hsnList, data: [], loading: false });
        }
      );
    } else {
      // delete payload.hsn_sac_code;
      setHSNList({ ...hsnList, data: [], loading: false });
    }
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        setMarketGroup({
          ...marketGroup,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    setBtnDisable(true);

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category: data.category.length
          ? data.category?.[0]?.categoryname
          : null,
        category_id: data.category.length ? data.category?.[0]?.uuid : null,
        marketgrp: data.marketGroup.length ? data.marketGroup?.[0]?.grpId : null,
        market_grp_id: data.marketGroup.length ? data.marketGroup?.[0]?.uuid : null,
        prodfamily: data.productFamily?.[0]?.familyId,
        prod_family_id: data.productFamily?.[0]?.uuid,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn.length > 0 ? data.hsn?.[0]?.hsn_sac_code : null,
        hsn_uuid: data.hsn.length > 0 ? data.hsn?.[0]?.uuid : null,
        machineCost: data.machineCost !== null ? data.machineCost : "0.00",
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        OEMnumber: data.OEMnumber,
        partNo: data.OEMnumber,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit?.[0]?.uuid : "",
        remark: data.remark,
        batch_tracking: data.batch_tracking,
      };

      if (props.main_obj.prod_overview?.uuid) {
        reqPayload.uuid = props.main_obj.prod_overview.uuid;

        updateProduct(reqPayload).then(
          (res) => {
            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              toast.success(res.data.message, {
                autoClose: 2000,
              });
              setTimeout(() => {
                navigate("/pcms/product");
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      } else {
        saveProduct(reqPayload).then(
          (res) => {
            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              toast.success(res.data.message, {
                autoClose: 2000,
              });
              setTimeout(() => {
                navigate("/pcms/product");
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_summary
              ? constLabel?.lbl_product_summary
              : "Product Summary"}
          </p>
          <div className="row">
            
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_market_group
                    ? constLabel?.lbl_market_group
                    : "Market group"}
                  {/* <span className="text-danger">*</span> */}
                <AddMarketGroup fetchMarketGroup={fetchMarketGroup} />
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.market_grp_id !== undefined &&
                    props.main_obj.prod_overview.market_grp_id !== null
                      ? [props.main_obj.prod_overview?.market_grp_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select market group"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.market_grp_id !==
                          undefined &&
                        props.main_obj.prod_overview.market_grp_id !== null
                          ? [props.main_obj.prod_overview?.market_grp_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />

                {/* {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger"> * </span>
                  <Tooltip id={"open-tooltip2"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip2"}
                    // data-tooltip-content={`Classifies items based on their role in the manufacturing process,
                    //   including categories such as raw materials, components, finished goods, packaging materials, tools, and gauges. 
                    //   This field helps in organizing and tracking inventory for streamlined production, procurement, and management.`}
                    data-tooltip-html=" Groups products that share common characteristics, functionalities, or manufacturing processes.<br /> 
                       This field helps categorize products into broader families, such as similar types of finished goods,<br /> 
                       components, or raw materials, enabling more efficient inventory management, reporting, and product<br />
                       lifecycle tracking."
                    height={20} width={20} />
                  <AddProductFamily fetchProductFamily={fetchProductFamily} />
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.prod_family_id !== undefined &&
                    props.main_obj.prod_overview.prod_family_id !== null
                      ? [props.main_obj.prod_overview?.prod_family_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.prod_family_id !==
                          undefined &&
                        props.main_obj.prod_overview.prod_family_id !== null
                          ? [props.main_obj.prod_overview?.prod_family_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />

                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold d-flex align-items-center">
                  {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                  {/* <span className="text-danger">*</span> */}
                  <Tooltip id={"open-tooltip1"} place="top" style={{ zIndex: 9999 }}/>
                  <img src={info} alt='info' className='cursor_pointer'
                    data-tooltip-id={"open-tooltip1"}
                    // data-tooltip-content={`Classifies items based on their role in the manufacturing process,
                    //   including categories such as raw materials, components, finished goods, packaging materials, tools, and gauges. 
                    //   This field helps in organizing and tracking inventory for streamlined production, procurement, and management.`}
                    data-tooltip-html="To classify products into distinct categories for easier<br />
                       management, searchability, and reporting within the system."
                    height={20} width={20} />
                  <AddProductCategory fetchProductCategory={fetchProductCategory} />
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.category !== undefined &&
                    props.main_obj.prod_overview.category !== null &&
                    props.main_obj.prod_overview.category_id !== null
                      ? [props.main_obj.prod_overview?.category_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      onChange={onChange}
                      defaultValue={
                        props.main_obj.prod_overview.category !== undefined &&
                        props.main_obj.prod_overview.category !== null &&
                        props.main_obj.prod_overview.category_id !== null
                          ? [props.main_obj.prod_overview?.category_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.category ? true : false}
                    />
                  )}
                />

                {/* {errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  defaultValue={
                    props.main_obj.prod_overview.productName !== null &&
                    props.main_obj.prod_overview.productName !== ""
                      ? props.main_obj.prod_overview.productName
                      : ""
                  }
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.openingstock !== null &&
                    props.main_obj.prod_overview.openingstock !== ""
                      ? props.main_obj.prod_overview.openingstock
                      : 0
                  }
                  placeholder="Enter opening stock"
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            {/* HSN select box */}
            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <Controller
                  name="hsn"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.hsn_uuid !== undefined &&
                    props.main_obj.prod_overview.hsn_uuid !== null
                      ? [props.main_obj.prod_overview?.hsn_uuid]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="hsn_sac_code"
                      className="mt-2"
                      multiple={false}
                      options={hsnList.data}
                      isLoading={hsnList.loading}
                      placeholder="Select hsn"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.hsn_uuid !== undefined &&
                        props.main_obj.prod_overview.hsn_uuid !== null
                          ? [props.main_obj.prod_overview?.hsn_uuid]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.hsn ? true : false}
                      onInputChange={(text) =>
                        setFilterConfig({ ...filterConfig, hsn_sac_code: text })
                      }
                    />
                  )}
                />
              </div>
            </div>

            {/* HSN select box */}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_unit ? constLabel?.lbl_unit : "Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.unit_id !== undefined &&
                    props.main_obj.prod_overview.unit_id !== null
                      ? [props.main_obj.prod_overview?.unit_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.unit_id !== undefined &&
                        props.main_obj.prod_overview.unit_id !== null
                          ? [props.main_obj.prod_overview?.unit_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />

                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_oem_part_no
                    ? constLabel?.lbl_oem_part_no
                    : "OEM Part No"}
                </label>
                <input
                  className={
                    errors.OEMnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter OEM part no"
                  name="OEMnumber"
                  defaultValue={
                    props.main_obj.prod_overview.OEMnumber !== null &&
                    props.main_obj.prod_overview.OEMnumber !== ""
                      ? props.main_obj.prod_overview.OEMnumber
                      : ""
                  }
                  {...register("OEMnumber")}
                />
                {errors.OEMnumber && (
                  <span className="text-danger err-msg">
                    {errors.OEMnumber.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_detail
              ? constLabel?.lbl_product_detail
              : "Product Detail"}
          </p>
          <div className="row col-12">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_sold
                  ? constLabel?.lbl_can_be_sold
                  : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_sold
                      ? props.main_obj.prod_overview.can_be_sold
                      : false
                  }
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_pruchase
                  ? constLabel?.lbl_can_be_pruchase
                  : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_purchased
                      ? props.main_obj.prod_overview.can_be_purchased
                      : false
                  }
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_batch_tracking
                  ? constLabel?.lbl_batch_tracking
                  : "Batch Tracking"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.batch_tracking
                      ? props.main_obj.prod_overview.batch_tracking
                      : false
                  }
                  name="batch_tracking"
                  {...register("batch_tracking")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_batch_tracking
                    ? constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
                </label>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_price
              ? constLabel?.lbl_product_price
              : "Product Price"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_cost_of_mfg ? constLabel?.lbl_cost_of_mfg : "Cost of Manufacturing"}
                  (<span> &#8377;</span>)
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost of manufacturing"
                  name="machineCost"
                  defaultValue={
                    props.main_obj.prod_overview.machineCost !== null &&
                    props.main_obj.prod_overview.machineCost !== ""
                      ? props.main_obj.prod_overview.machineCost
                      : "0.00"
                  }
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_selling_price
                    ? constLabel?.lbl_selling_price
                    : "Selling Price "}
                  <span className="text-danger"> * </span>
                  (<span> &#8377;</span>)
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="salesPrice"
                  defaultValue={
                    props.main_obj.prod_overview.salesPrice !== null &&
                    props.main_obj.prod_overview.salesPrice !== ""
                      ? props.main_obj.prod_overview.salesPrice
                      : 0.0
                  }
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_procurement_price
                    ? constLabel?.lbl_procurement_price
                    : "Procurement Price "}
                  <span className="text-danger"> * </span>
                  (<span> &#8377;</span>)
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter procurement price"
                  name="purchase_price"
                  defaultValue={
                    props.main_obj.prod_overview.purchase_price !== null &&
                    props.main_obj.prod_overview.purchase_price !== ""
                      ? props.main_obj.prod_overview.purchase_price
                      : 0.0
                  }
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                </label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.remark !== null &&
                    props.main_obj.prod_overview.remark !== ""
                      ? props.main_obj.prod_overview.remark
                      : ""
                  }
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GeneralItem;
