import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  createMaterialRequest,
  deleteMaterialRequest,
  getMaterialRequest,
  getProdList,
  getSalesOrder,
  import_po,
} from "../../../api/index";
import {
  calMaxPage_new,
  convertDateYYMMDD,
  getLabels,
  isExcelFile,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { Trash2, FilePlus } from "react-feather";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Material_Request_Col_name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import AccessDenied from "../../Common/AccessDenied";
import { FINALIZED } from "../../../common/Constants/constants";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";
import EditMaterialRequest from "./EditMaterialRequest";

const schema = yup
  .object()
  .shape({
    req_date: yup
      .date()
      .required("Please select date!")
      .typeError("Please select date!"),
    req_status: yup
      .array()
      .min(1, "Please select status!")
      .required("Please select status!"),
    remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
    items: yup.array().of(
      yup.object().shape({
        product: yup
          .array()
          .min(1, "Please select product!")
          .required("Please select product!"),
        quantity: yup
          .string()
          .required("Quantity is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid quantity!"),
      })
    ),
  })
  .required();

function MaterialRequest() {
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [constLabel, setConstLabel] = useState({});
  const [startDate, setStartDate] = useState(getPrvMonthDateFromToday);
  const [endDate, setEndDate] = useState(new Date());

  const [showLabel, setShowLabel] = useState(Material_Request_Col_name);
  const [visible2, setVisible2] = useState(false);
  const [salesOrders, setSalesOrders] = useState({ data: [], loading: false });
  const [prodList, setProdList] = useState({ data: [], loading: false });

  const [statusList, setStatusList] = useState({
    data: [
      { id: 1, name: "Draft" },
      { id: 2, name: "Finalized" },
      { id: 3, name: "Closed" },
    ],
    loading: false,
  });

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false,
  });

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            sales_order: [],
            product: [],
            quantity: 0,
          },
        ],
      },
    }); // initialise the hook

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  let navigate = useNavigate();

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Material Request"));
  }, []);

  useEffect(() => {
    fetchSalesOrder();
    fetchProdList();
    setValue("req_date", new Date());
  }, [visible2]);

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Material Request"));
    const debounceTimeout = setTimeout(() => {
      fetchMaterialRequestList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [startDate, endDate]);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function fetchSalesOrder() {
    setSalesOrders({ ...salesOrders, data: [], loading: true });

    getSalesOrder({ so_status: "Approved" }).then(
      (res) => {
        // console.log("res", res.data.data)
        setSalesOrders({ ...salesOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setSalesOrders({ ...salesOrders, data: [], loading: false });
      }
    );
  }

  function fetchProdList() {
    setProdList({ ...prodList, data: [], loading: true });

    getProdList().then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );
        setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchMaterialRequestList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };
    setDataList({ ...dataList, data: [], loading: true });

    getMaterialRequest(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));
        //console.log('ma',calMaxPage_new(parseInt(res.data.total),epp))
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }
  //console.log("max",maxPage);

  const delRecord = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMaterialRequest({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Material request deleted successfully", {
              autoClose: 2000,
            });

            if (dataList.data.length == 1 && page > 1) {
              fetchMaterialRequestList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchMaterialRequestList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function handleSalesOrder(index, selected){
    console.log("selected", selected);
    const formattedItems = selected?.[0]?.so_order_lines.map((item) => item.product_id);
    console.log("formattedItems", formattedItems);
    setProdList(prevState => ({ ...prevState, data: formattedItems }));
  }

  const onSubmit = (data) => {
    if (data !== "") {
        let reqPayload = [];
        for (let i = 0; i < data.items.length; i++) {
          const productUUID = data.items?.[i]?.product?.[0]?.uuid;
          const matchingItem = data.items?.[i]?.sales_order?.[0]?.so_order_lines?.find(item => item.product_id?.uuid === productUUID);
          
          const lineItems = {
              product_uuid: productUUID,
              sales_order_uuid: data.items?.[i]?.sales_order?.[0]?.uuid ?? "",
              sales_order_item_uuid: matchingItem ? matchingItem.uuid : "",
              quantity: data.items?.[i]?.quantity
          };
          reqPayload.push(lineItems);
      }

    const payload = {
        material_req_date: convertDateYYMMDD(data.req_date, "-"),
        material_req_remarks: data.remark,
        material_req_status: data.req_status?.[0]?.name,
        material_req_line_items: reqPayload
    }

      createMaterialRequest(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Material request saved successfully !", {
              autoClose: 3000,
            });
            setVisible2(false);
            fetchMaterialRequestList(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        <div className=" content"></div>
      </div>

      {!privilage.read ? (
        <AccessDenied />
      ) : (
        <div className="min-vh-100" id="root_div_main">
          <Modal
            show={visible2}
            onHide={() => {
              setVisible2(false);
              reset();
            }}
            size={"lg"}
          >
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_add_material_request
                  ? constLabel.lbl_add_material_request
                  : "Add Material Request"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form action="">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                        <span className="text-danger"> *</span>
                      </label>
                      <Controller
                        control={control}
                        name="req_date"
                        // defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            className={
                              errors.req_date
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholderText="Select date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {errors.req_date && (
                        <span className="text-danger err-msg">
                          {errors.req_date.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3 typeahead-container">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_status
                          ? constLabel.lbl_status
                          : "Status"}
                        <span className="text-danger"> *</span>
                      </label>

                      <Controller
                        name="req_status"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            // size="sm"
                            options={statusList.data}
                            isLoading={statusList.loading}
                            placeholder="Select status"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={errors.req_status ? true : false}
                          />
                        )}
                      />
                      {!statusList.loading && !errors.req_status && (
                        <span className="arrow-icon"></span>
                      )}
                      <span className="text-danger err-msg">
                        {errors.req_status?.message}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mt-md-0 mt-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                      >
                        {constLabel?.lbl_remark
                          ? constLabel.lbl_remark
                          : "Remark"}
                      </label>

                      <textarea
                        type="text"
                        className="form-control"
                        id="remark"
                        name="remark"
                        placeholder="Enter remark"
                        {...register("remark")}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <hr className="mb-1" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                    {constLabel?.lbl_item_detail
                      ? constLabel?.lbl_item_detail
                      : "Item Detail"}
                  </p>
                  <div>
                    <a
                      style={{ fontSize: "16px" }}
                      className="btn btn-sm button-primary px-2 mt-2"
                      onClick={() => {
                        append({
                          sales_order: [],
                          product: [],
                          quantity: 0,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          color: "#ffffff",
                          marginRight: "5px",
                        }}
                        icon={faAdd}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add ? constLabel?.lbl_add : "Add"}
                    </a>
                  </div>
                </div>
                <hr className="mt-1" />
                <div className="col-12">
                  {/* {fields.length > 0 ? ( */}
                  <div className="">
                    <table className="table my-2 table-stirped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            {constLabel?.lbl_sales_order
                              ? constLabel?.lbl_sales_order
                              : "Sales Order"}
                          </th>
                          <th>
                            {constLabel?.lbl_product
                              ? constLabel?.lbl_product
                              : "Product"}
                            <span className="text-danger"> *</span>
                          </th>
                          <th>
                            {constLabel?.lbl_quantity
                              ? constLabel?.lbl_quantity
                              : "Quantity"}
                          </th>
                          <th>{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id}>
                            <td style={{ verticalAlign: "top" }} width={"30%"}>
                              <Controller
                                name={`items[${index}].sales_order`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    size="sm"
                                    id="basic-typeahead"
                                    labelKey="so_id"
                                    multiple={false}
                                    options={salesOrders.data}
                                    isLoading={salesOrders.loading}
                                    placeholder="Select sales order"
                                    onChange={(selected) => {
                                      onChange(selected);
                                      if (selected && selected.length > 0) {
                                        handleSalesOrder(index, selected);
                                      } else {
                                        setValue(`items[${index}].product`, []);
                                        fetchProdList();
                                      }
                                    }}
                                    onBlur={onBlur}
                                    selected={value}
                                    // isInvalid={
                                    //   errors.items?.[index]?.sales_order
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                )}
                              />
                            </td>

                            <td style={{ verticalAlign: "top" }} width={"40%"}>
                              <Controller
                                name={`items[${index}].product`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    size="sm"
                                    id="basic-typeahead"
                                    labelKey="productName"
                                    multiple={false}
                                    options={prodList.data}
                                    isLoading={prodList.loading}
                                    placeholder="Select product"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.product
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />
                              {errors.items?.[index]?.product && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.product.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`)}
                                placeholder="Enter quantity"
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                              }}
                            >
                              <Tooltip
                                id={"delete-tooltip" + index}
                                place="top"
                              />
                              <Trash2
                                data-tooltip-id={"delete-tooltip" + index}
                                data-tooltip-content={"Delete"}
                                className="menu_icon_red cursor_pointer"
                                size={20}
                                onClick={
                                  index > 0
                                    ? () => {
                                        remove(index);
                                      }
                                    : null
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
              >
                {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
              </button>
            </Modal.Footer>
          </Modal>
          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    {/* <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    /> */}
                    <h1 className="bold">
                      {constLabel?.lbl_material_request
                        ? constLabel.lbl_material_request
                        : "Material Request"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          setVisible2(true);
                          reset();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_material_request
                          ? constLabel.lbl_add_material_request
                          : "Add Material Request"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList.data.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>

                            {showLabel.map((v, i) =>
                              v.label === "MR Number" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_po_id
                                    ? constLabel.lbl_po_id
                                    : "MR Number"}
                                </th>
                              ) : v.label === "Material Request Date" &&
                                v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_date
                                    ? constLabel.lbl_date
                                    : "Material Request Date"}
                                </th>
                              ) : v.label === "Status" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_status
                                    ? constLabel.lbl_status
                                    : "Status"}
                                </th>
                              ) : v.label === "Remark" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_remark
                                    ? constLabel.lbl_remark
                                    : "Remark"}
                                </th>
                              ) : null
                            )}

                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "MR Number" && item.flag ? (
                                  <td key={idx}>
                                    {" "}
                                    {val.material_req_id != "" &&
                                    val.material_req_id !== null
                                      ? val.material_req_id
                                      : "NA"}
                                  </td>
                                ) : item.label === "Material Request Date" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.material_req_date
                                      ? convertDateYYMMDD(
                                          new Date(val.material_req_date),
                                          "-"
                                        )
                                      : "NA"}
                                  </td>
                                ) : item.label === "Status" && item.flag ? (
                                  <td key={idx}>
                                    {val.status ? val.status : "NA"}
                                  </td>
                                ) : item.label === "Remark" && item.flag ? (
                                  <td key={idx}>
                                    {val.remarks ? val.remarks : "NA"}
                                  </td>
                                ) : null
                              )}

                              <td>
                                {privilage.write && (
                                  <EditMaterialRequest
                                    data={val}
                                    constLabel={constLabel}
                                    fetchMaterialRequestList={fetchMaterialRequestList}
                                    page={page}
                                    entriesPerPage={entriesPerPage}
                                  />
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip" + ind}
                                      place="top"
                                    />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_material_request
                                          ? constLabel.lbl_delete_material_request
                                          : "Delete Material Request"
                                      }
                                      className="menu_icon_red cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        delRecord(val);
                                        console.log("asdflk");
                                      }}
                                    />
                                  </>
                                )}

                                {privilage.write &&
                                  val.status === FINALIZED && (
                                    <>
                                      <Tooltip
                                        id={"po-tooltip" + ind}
                                        place="top"
                                      />
                                      <FilePlus
                                        data-tooltip-id={"delete-tooltip" + ind}
                                        data-tooltip-content={
                                          constLabel?.lbl_create_po
                                            ? constLabel.lbl_create_po
                                            : "Create Purchase Order"
                                        }
                                        className="menu_icon_grey cursor_pointer"
                                        size={20}
                                        onClick={() => {
                                          navigate(
                                            `/pcms/create/purchase-order/${btoa(
                                              "null"
                                            )}/${btoa("null")}`,
                                            { state: val }
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"No Material Request Found"}
                    </div>
                  )}

                  {dataList.loading ? null : (
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchMaterialRequestList(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchMaterialRequestList(page - 1, entriesPerPage);
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage || maxPage === 0 ? true : false
                          }
                          onClick={() => {
                            fetchMaterialRequestList(page + 1, entriesPerPage);
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default MaterialRequest;
