import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { formatDateString, adjustDateTime2, convertDateYYMMDD, newDateTimeForm, formatDate5 } from "../../../common/Functions/CommonFunctions";
import { create_mr_wo_jc_pro_plan } from "../../../api/index";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetState } from '../../../slices/production_planning/production_plan_slice';

function StepFive(props) {
    const { nextStep, prevStep } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);
    const StepThreeSavedData = useSelector((state) => state.steps.StepThree);
    const StepFourSavedData = useSelector((state) => state.steps.StepFour);



    const [previewData, setPreviewData] = useState({ work_order_list: [] });
    const [currentOpenWO, setCurrentOpenWO] = useState(0);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        let tempWOList = [];

        console.log("StepOneSavedData", StepOneSavedData);
        console.log("StepTwoSavedData", StepTwoSavedData);
        console.log("StepThreeSavedData", StepThreeSavedData);
        console.log("StepFourSavedData", StepFourSavedData);

        StepFourSavedData.uniProdBom.forEach((prod) => {
            const route = prod.selected_route;

            const workorder_obj = {
                productId: prod.productId,
                partNo: prod.partNo,
                uuid: prod.uuid,
                productName: prod.productName,
                unit: prod.unit_id.uom,
                bom: prod.selected_bom[0],
                sales_order_uuid: prod.so_uuid,
                sales_order_item_uuid: prod.so_item_uuid,
                party_id: prod.so_customer_id,
                amount: parseFloat(prod.so_final_total),
                length: prod.length,
                product_familyId: prod.product_familyId,
                consume_price: parseFloat(prod.so_final_total) * parseFloat(prod.so_item_quantity),
                location: StepOneSavedData.formData.location[0],
                organization: StepOneSavedData.formData.organization[0],
                quantity: prod.so_item_quantity,
                // status: "Draft",
                job_card_list: []
            }


            const wo_create_in_released = prod.selected_bom[0].bom_line_item.every((bom_item) => {
                return (parseFloat(bom_item.availqty) >= parseFloat(bom_item.qtyRequiredAsPerBom));
            });

            console.log("wo_create_in_released", wo_create_in_released);

            if (wo_create_in_released) {
                workorder_obj.status = "Released";
            } else {
                workorder_obj.status = "Draft";
            }

            // for (const bom_item of prod.selected_bom[0]) {
            //     var qtyReqToMakeOne = parseFloat(val.lineItemDetails.qty)
            //         / parseFloat(workorder_obj.bom.qty);

            //         StepTwoSavedData.subprodListWithBom.data

            // }




            // changing logic based on the routes selected, product routes or global routes
            if (prod.routeType == "global_routes") {
                workorder_obj.route = {
                    routes_id: route.uuid,
                    routes1: route.routes1,
                    routesname: route.routeName,
                    routeType: "global_routes"
                }
            }

            if (prod.routeType == "product_routes") {
                workorder_obj.route = {
                    routes_id: route.routes_id,
                    // routes1: route.routes1,
                    routesname: route.routesname,
                    routeType: "product_routes"
                }
            }


            route.route_operation_list.forEach((val) => {
                if (val.create_job_card) {
                    workorder_obj.job_card_list.push(val.create_job_card);
                }
            })


            tempWOList.push(workorder_obj);
        })

        console.log("tempWOList", tempWOList);
        setPreviewData({ ...previewData, work_order_list: tempWOList });
        // StepFourSavedData.uniProdBom
    }, [])

    function submitStep() {
        // setDisableButton(true);
        const StepTwoSavedDataCopy = JSON.parse(JSON.stringify(StepTwoSavedData));

        console.log("StepTwoSavedDataCopy", StepTwoSavedDataCopy);


        const payload = {
            production_plan_uuid: StepOneSavedData.prod_plan_uuid,
            ignore_available_qty: StepTwoSavedDataCopy.ignoreQty,
            // material_resource_planning: StepOneSavedData.formData.rawMaterialPlanning,
            // resource_planning: StepOneSavedData.formData.employeePlanning,
            // material_req_remarks: StepThreeSavedData.material_req_remarks,
            // material_req_status: StepThreeSavedData.material_req_status[0].name,
            draft_data: {
                StepOneSavedData: StepOneSavedData,
            },
            prod_plan_lineitems: [],
            work_order_list: [],
        }

        if (StepOneSavedData.formData.rawMaterialPlanning && StepOneSavedData.formData.employeePlanning) {
            payload.material_req_remarks = StepThreeSavedData.material_req_remarks;
            payload.material_req_status = StepThreeSavedData.material_req_status[0].name;
        }


        let ind = 0;
        for (const item of StepTwoSavedDataCopy.subprodListWithBom.data) {
            let obj = {
                product_uuid: item.lineItemDetails.product_id,
                // bom_uuid: item.lineItemDetails.bomid_id,
                sales_order_item_uuid: item.lineItemDetails.so_item_uuid,
                sales_order_uuid: item.lineItemDetails.so_uuid,
                materialCode: item.lineItemDetails.materialCode,
                bom_uuid: item.uuid ?? null,
            }

            // if we are creating material request and also resource planning only then executing if condition 
            if (StepOneSavedData.formData.rawMaterialPlanning && StepOneSavedData.formData.employeePlanning) {
                obj.quantity = StepThreeSavedData.items[ind].qty;
                item.lineItemDetails.planToRequestQty = StepThreeSavedData.items[ind].qty;
            } else {
                obj.quantity = item.lineItemDetails.planToRequestQty;
                item.lineItemDetails.planToRequestQty = item.lineItemDetails.planToRequestQty;
            }


            if (Number(item.lineItemDetails.planToRequestQty) !== 0) {
                payload.prod_plan_lineitems.push(obj);
            }

            ind++;
        }

        console.log("previewData", previewData);

        for (const workorder of previewData.work_order_list) {
            const workorder_obj = {
                quantity: workorder.quantity,
                start_date: convertDateYYMMDD(StepFourSavedData.formData.startDate, "-"),
                end_date: convertDateYYMMDD(StepFourSavedData.formData.endDate, "-"),
                org_id: workorder.organization.uuid,
                part_no: workorder.partNo,
                location_id: workorder.location.uuid,
                product_id: workorder.uuid,
                bom_uuid: workorder.bom.uuid,
                bom: workorder.bom,
                // route_uuid: workorder.route.routes_id,
                productId: workorder.productId,
                product_name: workorder.productName,
                unit: workorder.unit,
                sales_order_uuid: workorder.sales_order_uuid,
                sales_order_item_uuid: workorder.sales_order_item_uuid,
                party_id: workorder.party_id,
                work_order_status: workorder.status,
                job_card_list: [],
                amount: workorder.amount,
                consume_price: workorder.consume_price,
            }

            // if (StepFourSavedData.formData.global_route.length > 0) {
            if (workorder.route.routeType == "global_routes") {
                workorder_obj.global_route_uuid = workorder.route.routes_id;

            } else if (workorder.route.routeType == "product_routes") {
                workorder_obj.route_uuid = workorder.route.routes_id;

            }

            for (const jobcard of workorder.job_card_list) {
                const startDateTime = newDateTimeForm(new Date(jobcard.startDate), new Date(jobcard.startTime));
                const endDateTime = newDateTimeForm(new Date(jobcard.endDate), new Date(jobcard.endTime));

                const jobcard_obj = {
                    product_id: jobcard.product[0].uuid,
                    cycle_time: jobcard.cycle_time,
                    remarks: jobcard.remarks,
                    start_time: startDateTime,
                    end_time: endDateTime,
                    total_time_hrs: jobcard.total_time_hrs,
                    total_time_mins: jobcard.total_time_mins,
                    total_time_sec: jobcard.total_time_sec,
                    machine: jobcard.machine[0].machine_id_id,
                    operator: jobcard.operator[0].uuid,
                    planned_qty: Number(jobcard.target_qty),
                    status: jobcard.status[0].statusName
                }

                if (workorder.route.routeType == "global_routes") {
                    jobcard_obj.operation_uuid = jobcard.prod_operation[0].operation_id;

                } else if (workorder.route.routeType == "product_routes") {
                    jobcard_obj.product_operation_id = jobcard.prod_operation[0].uuid;
                    jobcard_obj.operation_uuid = jobcard.prod_operation[0].operation_id;

                }

                workorder_obj.job_card_list.push(jobcard_obj);
            }

            payload.work_order_list.push(workorder_obj);
        }

        console.log("payload", payload);


        if (payload.prod_plan_lineitems.length == 0) {
            delete payload.prod_plan_lineitems;
        }

        console.log("payload", payload);
        create_mr_wo_jc_pro_plan(payload).then((res) => {
            toast.success("Production plan created successfully", {
                autoClose: 2000,
            });


            localStorage.removeItem("StepOne");
            localStorage.removeItem("StepTwo");
            localStorage.removeItem("StepThree");
            localStorage.removeItem("StepFour");
            // dispatch(resetState());
            setTimeout(() => {

                navigate(`${process.env.PUBLIC_URL}/pcms/production_planning`);
            }, [2000]);

        }, (err) => {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            });

            setDisableButton(false);
        });

    }

    return (
        <React.Fragment>
            <div className='stepFive'>
                <div className='row' id='row1'>
                    <div className='col-12'>
                        <h3 className='mb-2 mt-4' id='pro_plan_label'>Preview Work Orders & Job Cards </h3>

                        {previewData.work_order_list.length > 0 ?
                            previewData.work_order_list.map((wo, wo_ind) => (

                                <Accordion activeKey={currentOpenWO} key={wo_ind} className='mt-3'>
                                    <Accordion.Item eventKey={wo_ind} >
                                        <Accordion.Header
                                            onClick={() => {
                                                if (currentOpenWO == wo_ind) {
                                                    setCurrentOpenWO(null);
                                                } else {
                                                    setCurrentOpenWO(wo_ind);
                                                }
                                            }}
                                        ><strong>{`${wo_ind + 1}. ${wo.productName} - ${wo.bom.bomId}`}</strong></Accordion.Header>
                                        <Accordion.Body>

                                            <section>
                                                <h6>Work Order Details</h6>
                                                <div className='row bg-lightgrey' id='work_order_row'>


                                                    <div className='col-12 col-md-2'>
                                                        <p className='mb-1 key'>Organization</p>
                                                        <p className='mb-2 value'>{wo.organization.comName}</p>
                                                    </div>
                                                    <div className='col-12 col-md-2'>
                                                        <p className='mb-1 key'>location</p>
                                                        <p className='mb-2 value'>{wo.location.locationname}</p>
                                                    </div>
                                                    <div className='col-12 col-md-2'>
                                                        <p className='mb-1 key'>Route</p>
                                                        <p className='mb-2 value'>{wo.route.routesname}</p>
                                                    </div>
                                                    <div className='col-12 col-md-2'>
                                                        <p className='mb-1 key'>Quantity</p>
                                                        <p className='mb-2 value'>{wo.quantity}</p>
                                                    </div>
                                                    <div className='col-12 col-md-2'>
                                                        <p className='mb-1 key'>Status</p>
                                                        <p className='mb-2 value'>{wo.status}</p>
                                                    </div>
                                                </div>
                                            </section>




                                            <section className='mt-4 pt-1'>
                                                <h6>Job Card Details</h6>
                                                {wo.job_card_list.map((jc) => (
                                                    <div className='row bg-lightgrey' id='job_card_row'>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Operation</p>
                                                            <p className='mb-2 value'>{jc.prod_operation[0].operationname}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Machine</p>
                                                            <p className='mb-2 value'>{jc.machine[0].name}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Operator</p>
                                                            <p className='mb-2 value'>{jc.operator[0].FirstName + " " + jc.operator[0].LastName}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Target Qty</p>
                                                            <p className='mb-2 value'>{jc.target_qty}</p>
                                                        </div>

                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Cycle Time</p>
                                                            <p className='mb-2 value'>{jc.cycle_time}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Status</p>
                                                            <p className='mb-2 value'>{jc.status[0].statusName}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Start Date</p>
                                                            <p className='mb-2 value'>{formatDateString(new Date(jc.startDate))}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>Start Time</p>
                                                            <p className='mb-2 value'> {new Date(adjustDateTime2(jc.startTime)).toLocaleTimeString("en-US", {
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                            })}</p>
                                                            {/* <p className='mb-2 value'>{new Date(jc.startTime).getMinutes()}</p> */}

                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>End Date</p>
                                                            <p className='mb-2 value'>{formatDateString(new Date(jc.endDate))}</p>
                                                        </div>
                                                        <div className='col-12 col-md-2'>
                                                            <p className='mb-1 key'>End Time</p>
                                                            <p className='mb-2 value'> {new Date(adjustDateTime2(jc.endTime)).toLocaleTimeString("en-US", {
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                            })}</p>
                                                            {/* <p className='mb-2 value'>{new Date(jc.endTime).getMinutes()}</p> */}

                                                        </div>

                                                    </div>
                                                ))}
                                            </section>

                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                            ))

                            :
                            "No work orders found"
                        }

                        <div className='d-flex justify-content-end mt-4'>
                            <button className='btn btn-grey px-4'
                                disabled={disableButton}
                                onClick={() => prevStep()}
                            >Previous</button>
                            <button
                                disabled={disableButton}
                                onClick={() => submitStep()}
                                className='btn button-primary px-4 ms-0 ms-md-2'
                            >Complete Planning</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default StepFive