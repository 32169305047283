import React, { useState, useEffect } from "react";
import logo from "../../src/assets/images/prodi_tm.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { masters_count, PcmsSignIn } from "../constant";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../slices/authentication/authThunk";
// import loginImg from "../../src/assets/images/loginImg.png";
import loginImg from "../../src/assets/images/ProdiLogInFront.png";
import loginImg2 from "../../src/assets/images/loginImg2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { fetchModules } from "../slices/modules/modules";
import { Carousel, Spinner } from "react-bootstrap";
import { findRoute } from "../common/Functions/CommonFunctions";
import { get_masters_count } from "../api";

const schema = yup
  .object()
  .shape({
    username: yup.string().required("Username is required !"),
    password: yup.string().required("Password is required !"),
  })
  .required();

function NewLogin() {
  const [username, setUsername] = useState("admin");
  const [password, setPassword] = useState("Tech@123");
  const [showPassword, setShowPassword] = useState(false);
  const [loginClick, setLoginClick] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    let val = !isChecked;

    if (val) {
      localStorage.setItem("congnito", true);
      localStorage.setItem("normal", false);
    } else {
      localStorage.setItem("congnito", false);
      localStorage.setItem("normal", true);
    }

    setIsChecked(!isChecked);
  };

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  const { errors } = formState;

  function transformKeys(data) {
    return data.map((item) => {
      const transformedItem = { ...item }; // Create a copy of the item

      // Rename the key "module" to "module_name"
      if (transformedItem.hasOwnProperty("module")) {
        transformedItem.module_name = transformedItem.module;
        delete transformedItem.module;
      }

      // Rename the key "screens" to "screen_list"
      if (transformedItem.hasOwnProperty("screens")) {
        transformedItem.screen_list = transformedItem.screens;
        delete transformedItem.screens;
      }

      return transformedItem;
    });
  }

  useEffect(() => {
    if (state.loginData?.data.statusCode == 200) {
      let congnito = localStorage.getItem("congnito");
      let normal = localStorage.getItem("normal");
      let data;
      if (congnito == "true") {
        localStorage.setItem(
          "access",
          state.loginData?.data.response.accessToken
        );
        localStorage.setItem("id", state.loginData?.data.response.idToken);

        data = { ...state.loginData?.data.response };
        delete data.accessToken;
        delete data.idToken;
      } else {
        localStorage.setItem("access", state.loginData?.data.response.token);

        data = { ...state.loginData?.data.response };
        delete data.token;
      }

      if (state.loginData.data?.response?.module_list?.length > 0) {
        const transformedArray = transformKeys(
          state.loginData.data?.response?.module_list
        );

        localStorage.setItem("lablelist", JSON.stringify(transformedArray));
        localStorage.setItem(
          "configuration",
          JSON.stringify(state.loginData?.data?.response?.configuration)
        );
      }

      const url = process.env.REACT_APP_API_INV_URL  + masters_count;

      // After Onboarding get record count of primary masters
      // get_masters_count().then(
      axios.get(url, {
        headers:{ "x-id-token": state.loginData?.data.response.idToken}
      }).then (
        (res) => {
          // console.log("masters count", res);
          localStorage.setItem("mst_count", JSON.stringify(res.data));
          localStorage.setItem("localData", JSON.stringify(data));

          // dispatch(fetchModules());

          setLoginClick(false);

          toast.success("Login Successfully !", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        
        setTimeout(() => {
          if(
            res.data.customerMaster === 0 ||
            res.data.supplierMaster === 0 ||
            res.data.machineMaster === 0 ||
            res.data.operationMaster === 0 ||
            res.data.rawMaterial === 0 &&
            (res.data.ManufacturedGoods === 0 || res.data.finishedGoods === 0 )
          ){
            navigate(`${process.env.PUBLIC_URL}/onboarding/setMasters`);
          } else {
            if (
              state.loginData.data.response.role_name.toLowerCase() == "admin"
            ) {
              navigate(`${process.env.PUBLIC_URL}/pcms/adminDashboard`);
            } else if (
              state.loginData.data.response.role_name.toLowerCase() ==
              "client_admin"
            ) {
              navigate(`${process.env.PUBLIC_URL}/pcms/adminDashboard`);
            } else if (
              state.loginData.data.response.role_name.toLowerCase() == "qms_admin"
            ) {
              navigate(`${process.env.PUBLIC_URL}/pcms/nc`);
            } else if (
              state.loginData.data.response.role_name.toLowerCase() ==
              "qms_operations"
            ) {
              navigate(`${process.env.PUBLIC_URL}/pcms/nc`);
            } else {
              const resultRoute = findRoute(
                state?.loginData?.data?.response?.module_list[0]?.screens[0]
              );

              navigate(resultRoute);
            }
          }
        }, 100);

        },
        (err) => {
          console.log("err", err);
        }
      );
      // After Onboarding get record count of primary masters

      // delete data.module_list;
    } else {
      if (state.error !== null && loginClick) {
        setError("password", {
          type: "custom",
          message: "Invalid username or password !",
        });
        localStorage.removeItem("localData");
        setLoginClick(false);
      }

    }
  }, [state]);

  const onSubmit = (data) => {
    if (isChecked) {
      localStorage.setItem("congnito", true);
      localStorage.setItem("normal", false);
    } else {
      localStorage.setItem("congnito", false);
      localStorage.setItem("normal", true);
    }
    if (data !== "") {
      dispatch(loginUser(data));
      setLoginClick(true);
    } else {
      errors.showMessages();
      setLoginClick(false);
    }
  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div
          className="card rounded-0 border-0 shadow-dark"
          style={{ width: "70%" }}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              <Carousel
                prevIcon={false}
                nextIcon={false}
                interval={null}
                indicators={null}
              >
                {/* <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={loginImg2}
                    alt="First slide"
                    style={{ height: "520px" }}
                  />
                  <Carousel.Caption style={{ marginBottom: "3rem" }}>
                    <div
                      className="text_over_img1 roboto"
                      style={{ color: "black" }}
                    >
                      <p>
                        Elevate Your Production Standards with our integrated
                        production control and quality management solution
                      </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item> */}
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={loginImg}
                    alt="Third slide"
                    style={{ height: "520px", filter: "brightness(0.5)" }}
                  />
                  <Carousel.Caption style={{ marginBottom: "1rem" }}>
                    <div className="text-start">
                      <p className="roboto body3 w-100 my-0 py-4">
                        Smart MOS Solution for SME Manufacturers
                      </p>
                      {/* <h3 className="bold w-100"></h3> */}
                      <h3 className="bold w-100">
                        Empowering Manufacturing Excellence with PRODi
                      </h3>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex justify-content-center">
                <img src={logo} height={130} width={150}></img>
              </div>
              <div className="text-center">
                <h1 className="bold w-100">Welcome to PRODi</h1>
                <p className="roboto w-100">
                  Streamlining Your Manufacturing Process
                </p>
              </div>

              <div className="mx-auto px-4">
                {/* <div className="d-flex justify-content-start align-items-center mb-2">
                  <p className="mb-0 mt-1 roboto-bold body1">{"Login"}</p>
                </div> */}
                <div>
                  <form>
                    <div className="col">
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Username or Email"
                          {...register("username")}
                          id="username"
                        />
                      </div>
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.username?.message}
                      </span>
                    </div>
                    <div className="col mt-3">
                      <div className="input-group">
                        <input
                          className="form-control "
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          {...register("password")}
                          id="password"
                        />
                        <span className="input-group-text">
                          {showPassword ? (
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: "pointer" }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: "pointer" }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword(true)}
                            />
                          )}
                        </span>
                      </div>
                      <span
                        className="text-danger"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.password?.message}
                      </span>
                    </div>
                    <div className="col mt-1 text-end body3">
                      {/* <a href="#" 
                        onClick={(e) => {
                          e.preventDefault();
                          SweetAlert.fire({
                            title: "Work In Progress!",
                            text: "Thank you for your patience",
                            icon: "info",
                            confirmButtonText: "Ok"
                          })
                        }}
                      >Forgot Password</a> */}
                      <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>
                        Forgot Password?
                      </Link>
                    </div>

                    <button
                      className="btn button-primary w-100 mt-4 roboto-bold"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      style={{ letterSpacing: "1px" }}
                      disabled={loginClick}
                    >
                      LOGIN{" "}
                      {loginClick ? (
                        <Spinner animation="border" size="sm" />
                      ) : null}{" "}
                    </button>

                    <div className="col mt-3 text-center">
                      New on our platform?{" "}
                      <Link to={`${process.env.PUBLIC_URL}/onBoarding`}>
                        Register
                      </Link>
                    </div>

                    {/* <hr className="pt-1" />

                    <p className="mb-2 mt-2" style={{ fontSize: "14px" }}>
                      Not yet registered? Sign up now
                    </p>
                    <button
                      className="btn btn-outline-secondary w-100 mt-1 roboto-bold"
                      type="submit"
                      style={{ letterSpacing: "1px" }}
                    >
                      Sign up{" "}
                    </button> */}
                  </form>
                </div>

                <div>
                  <div className="form-check form-switch mt-3 invisible">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label lbl-style roboto-bold"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      {"Cognito"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewLogin;
